<template>
  <transition :name="down ? 'expand-down' : 'expand'" @enter="enter" @after-enter="afterEnter" @leave="leave" @after-leave="afterLeave">
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'TransitionExpand',
  props: {
    down: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    enter(element) {
      const width = getComputedStyle(element).width

      element.style.width = width
      element.style.position = 'absolute'
      element.style.visibility = 'hidden'
      element.style.height = 'auto'

      const height = getComputedStyle(element).height

      element.style.width = null
      element.style.position = null
      element.style.visibility = null
      element.style.height = 0

      getComputedStyle(element).height

      requestAnimationFrame(() => {
        element.style.height = height
      })
    },
    afterEnter(element) {
      element.style.height = 'auto'
      this.$emit('transitionEnter')
    },
    leave(element) {
      const height = getComputedStyle(element).height
      
      element.style.height = height

      getComputedStyle(element).height

      requestAnimationFrame(() => {
        element.style.height = 0
      })
    },
    afterLeave () {
      this.$emit('transitionLeave')
    }
  }
}
</script>

<style lang="scss" scoped>
  * {
    // Hints to browsers how an element is expected to change
    // (potential performance boost)
    will-change: height;
    backface-visibility: hidden;
  }

  .expand-enter-active,
  .expand-leave-active {
    transition: height .4s ease;
    overflow: hidden;
  }
  .expand-enter-from,
  .expand-leave-to {
    height: 0;
  }

  .expand-down-enter-active {
    transition: height .4s ease;
    overflow: hidden;
  }
  .expand-down-enter-from {
    height: 0;
  }
</style>