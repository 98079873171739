export default {
  "en": "https://images.iphonephotographyschool.com/36585/en.svg",
  "en-au": "https://images.iphonephotographyschool.com/36582/en-au.svg",
  "en-ca": "https://images.iphonephotographyschool.com/36583/en-ca.svg",
  "en-gb": "https://images.iphonephotographyschool.com/36585/en.svg",
  "en-us": "https://images.iphonephotographyschool.com/36584/en-us.svg",
  "es": "https://images.iphonephotographyschool.com/36587/es.svg",
  "es-mx": "https://images.iphonephotographyschool.com/36586/es-mx.svg",
  "es-es": "https://images.iphonephotographyschool.com/36587/es.svg",
  "pt": "https://images.iphonephotographyschool.com/36590/pt.svg",
  "pt-br": "https://images.iphonephotographyschool.com/36589/pt-br.svg",
  "fr": "https://images.iphonephotographyschool.com/36588/fr.svg",
  "fr-fr": "https://images.iphonephotographyschool.com/36588/fr.svg",
  "de": "https://images.iphonephotographyschool.com/36581/de.svg",
  "de-de": "https://images.iphonephotographyschool.com/36581/de.svg",
};